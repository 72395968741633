export default {
    nl: {
        title: 'Wachtwoord wijzigen',
        email: 'E-mailadres',
        newPassword: 'Nieuw wachtwoord',
        submit: 'Opslaan',
        error: "Het is niet gelukt om je wachtwoord te resetten. Zorg ervoor dat het  nieuwe wachtwoord voldoet aan de wachtwoordcriteria. Probeer het opnieuw of neem contact op met <a href='mailto:servicedesk@itaa.be'>servicedesk@itaa.be</a>.",
        requirements: "<span>Wachtwoord Criteria</span><ul><li>Minimaal 8 tekens lang</li><li>Minstens één hoofdletter (A-Z)</li><li>Minstens één kleine letter (a-z)</li><li>Minstens één cijfer (0-9)</li><li>Minstens één speciaal teken (!@#$%^&*)</li><li>Het wachtwoord mag geen delen van uw e-mailadres bevatten</li></ul>",
    },
    fr: {
        title: 'Modifier le mot de passe',
        email: 'Adresse e-mail',
        newPassword: 'Nouveau mot de passe',
        submit: 'Sauvegarder',
        error: "Nous n'avons pas pu réinitialiser votre mot de passe. Assurez-vous que  le nouveau mot de passe respecte les critères de sécurité. Veuillez réessayer ou contacter <a href='mailto:servicedesk@itaa.be'>servicedesk@itaa.be</a>.",
        requirements: "<span>Critères de Mot de Passe</span><ul><li>Au moins 8 caractères</li><li>Au moins une lettre majuscule (A-Z)</li><li>Au moins une lettre minuscule (a-z)</li><li>Au moins un chiffre (0-9)</li><li>Au moins un caractère spécial (!@#$%^&*)</li><li>Le mot de passe ne doit pas contenir de parties de votre adresse e-mail</li></ul>",
    }
}