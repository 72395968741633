import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import LandingPageData from './data/LandingPageData';
import { getUserVisibilities, getActiveInfoMessages, getNaturalPersonInformationValidation, getLegalPersonInformationValidation, getEstoxLink, getEstoxAdminLink, getInisLink, getCompanyWebLink } from './http/HttpClientHelper';
import { useOutletContext } from "react-router-dom";
import GeneralAssembly from './GeneralAssembly/GeneralAssembly';
import Mandate from './Mandate/Mandate';
import Estox from './Estox/Estox';
import LandingPageModal from './LandingPageModal/LandingPageModal';
import { useTranslation } from 'react-i18next';
import { getExcelForAccountancyUrl } from './Mandate/http/HttpClientHelper';
import { getExamUrl } from './Estox/http/HttpClientHelper';
import showToastMessage from '../../Toast/ToastMessage';

const LandingPage = ({ setNavigatedFromModalNaturalPerson }) => {
    const [userVisibilities, setUserVisibilities] = useState(null);
    const [infoMessages, setActiveInfoMessages] = useState(null);
    const { searchQuery, userDetail, isMobile } = useOutletContext();
    const [searchTerm, setSearchTerm] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showNaturalPersonConfirmationModal, setShowNaturalPersonConfirmationModal] = useState(false);
    const [showMandateModal, setShowMandateModal] = useState(false);
    const [showEstoxModal, setShowEstoxModal] = useState(false);
    const [showLegalPersonConfirmationModal, setShowLegalPersonConfirmationModal] = useState(false);
    const [naturalPersonInformationValidationDataState, setNaturalPersonInformationValidationDataState] = useState({});
    const [legalPersonInformationValidationDataState, setLegalPersonInformationValidationDataState] = useState({});
    const [hasEstoxRights, setHasEstoxRights] = useState(false);
    const [hasEstoxAdminRights, setHasEstoxAdminRights] = useState(false);
    const [estoxLinkFetched, setEstoxLinkFetched] = useState(true);
    const [estoxLinkClicked, setEstoxLinkClicked] = useState(false);
    const [inisLinkClicked, setInisLinkClicked] = useState(false);
    const [companyWebLinkClicked, setCompanyWebLinkClicked] = useState(false);
    const [estoxAdminLinkClicked, setEstoxAdminLinkClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [excelForAccountancyLink, setExcelForAccountancyLink] = useState('');
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language.toUpperCase();

    const toggleRefLink = (url) => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        if (userDetail?.id) {
            const fetchValidationData = async () => {
                if (userVisibilities?.isVisibleEloket && !userDetail.isImpersonated) {
                    await getNaturalPersonInformationValidation(userDetail.id).then(async information => {
                        setNaturalPersonInformationValidationDataState(information);
                        if (information.shouldConfirm || information.validateNationalNumber) {
                            setShowNaturalPersonConfirmationModal(true);
                        }
                    });
                }
            }
            fetchValidationData();
        }
    }, [userVisibilities, i18n.language])

    useEffect(() => {
        localStorage.removeItem('navbarData')
        if (userDetail?.id) {
            Promise.all([
                getUserVisibilities(userDetail.id),
                getActiveInfoMessages()
            ]).then(([visibilitiesData, messagesData]) => {
                setUserVisibilities(visibilitiesData);
                setActiveInfoMessages(messagesData);
                setIsLoading(false);
            }).catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
        }

        var lang = localStorage.getItem('i18nextLng');
        if (lang !== 'nl' && lang !== 'fr') {
            localStorage.setItem('i18nextLng', 'nl');
        }
    }, [userDetail?.id]);

    const fetchEstoxAdminLink = async () => {
        try {
            const link = await getEstoxAdminLink(userDetail.id);
            return link.estoxLink;
        } catch (error) {
            console.error(`Error fetching estox admin link:`, error);
            return '';
        }
    }
    const fetchExcelForAccountancyLink = async () => {
        var hash = await getExcelForAccountancyUrl();
        return hash;
    }
    const fetchInisHash = async (naturalPersonId) => {
        try {
            const link = await getInisLink(naturalPersonId);
            return link.hash;
        } catch (error) {
            console.error(`Error fetching education link`, error);
            return '';
        }
    }

    const fetchCompanyWebLink = async (naturalPersonId) => {
        try {
            const link = await getCompanyWebLink(naturalPersonId);
            return link.data;
        } catch (error) {
            console.error(`Error fetching companyweb link`, error);
            return '';
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (hasEstoxRights && estoxLinkClicked) {
                setEstoxLinkFetched(true);
                setEstoxLinkClicked(false);
            }

            if (hasEstoxAdminRights && estoxAdminLinkClicked) {
                setEstoxAdminLinkClicked(false);
            }

            if (inisLinkClicked) {
                setInisLinkClicked(false);
            }

            if (companyWebLinkClicked) {
                setCompanyWebLinkClicked(false);
            }
        };

        fetchData();
    }, [hasEstoxRights, hasEstoxAdminRights, estoxLinkClicked, estoxAdminLinkClicked, inisLinkClicked, companyWebLinkClicked]);

    const isItemVisible = (key) => {
        if (userVisibilities && userVisibilities[key] === true) {
            return true;
        }
        return false;
    };


    const handleCardClick = async (item) => {
        let link = item.link;
        let postData = '';
        let showRegistrationIdError = false;
        switch (item.key) {
            case "isVisibleEstox":
                setShowEstoxModal(true);
                break;
            case "isVisibleEstoxAdmin":
                setEstoxAdminLinkClicked(true);
                link = await fetchEstoxAdminLink();
                break;

            case "isVisibleMandateManagement":
                setShowMandateModal(true);
                break;
            case "isVisibleExcelForAccountancy":
                const hashExcel = await fetchExcelForAccountancyLink(); // Ensure this returns a promise that resolves to the token
                link = process.env.REACT_APP_URL_EXCELFORACCOUNTANCY
                    .replace('{0}', hashExcel)
                    .replace('{1}', i18n.language);
            case "isVisibleInisSystemEducation":
            case "isVisibleInisSystemFacturation":
                setInisLinkClicked(true);
                const hash = await fetchInisHash(userDetail.id);
                if (item.key === "isVisibleInisSystemEducation") {
                    link = process.env.REACT_APP_URL_INIS.replace('{0}', hash);
                }
                else if (item.key === "isVisibleInisSystemFacturation") {
                    link = process.env.REACT_APP_URL_INIS_SHOWCLIENT.replace('{0}', hash);
                }
                break;
            case "isVisibleCompanyWeb":
                setCompanyWebLinkClicked(true);
                postData = await fetchCompanyWebLink(userDetail.id);
                break;
            case "isVisibleEntranceExam":
            case "isVisibleIntermediateExam":
            case "isVisibleIntermediateExam2":
            case "isVisibleTransferExam":
            case "isVisibleBackpackExam":
            case "isVisibleFinalExam":
            case "isVisibleCertifiedExam":
                var registrationId = await getExamUrl(userDetail.id, item.examType);
                if(!registrationId){
                    showRegistrationIdError = true;
                    showToastMessage(t('errors.examRegistrationNotAvailable'));
                }
                link = `${process.env.REACT_APP_URL_EXAM}${registrationId}`
                break;
            default:
                break;
        }

        if (item.openInModal) {
            setShowAddModal(true);
        } else if (item.key === "isVisibleCompanyWeb") {
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = item.link;
            form.target = '_blank';

            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = 'parm';
            hiddenField.value = postData;

            form.appendChild(hiddenField);
            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        } else {
            if (item.openInNewTab) {
                if(!showRegistrationIdError){
                    window.open(link, "_blank");
                }
            } else {
                window.location.href = link;
            }
        }
    };

    const filteredData = LandingPageData.filter(item => {
        if (item.key === "isVisibleEstox") {
            if (isItemVisible("isVisibleEstox")) {
                if (hasEstoxRights !== true) {
                    setHasEstoxRights(true);
                }
            }
        }
        if (item.key === "isVisibleEstoxAdmin") {
            if (isItemVisible("isVisibleEstoxAdmin")) {
                if (hasEstoxAdminRights !== true) {
                    setHasEstoxAdminRights(true);
                }
            }
        }

        return (
            isItemVisible(item.key) &&
            (item.title.toLowerCase().includes(isMobile ? searchTerm.toLowerCase() : searchQuery.toLowerCase()))
        );
    });

    return (
        <div className="container" id="main">
            {userDetail?.id && (
                <>
                    {(estoxLinkClicked || estoxAdminLinkClicked) && (
                        <div className="loader-overlay">
                            <div className="loader"></div>
                        </div>
                    )}
                    {infoMessages?.map(item => (
                        <Row className='row-m-1' key={item.id}>
                            <Col md={12}>
                                <div className='alert alert-danger mb-0'>
                                    <strong>{item[`title_${currentLanguage}`]}</strong><br />
                                    <span>{item[`content_${currentLanguage}`]}</span>
                                </div>
                            </Col>
                        </Row>
                    ))}

                    <Row className='row-m-1'>
                        {!isLoading && filteredData.length === 0 && (
                            <Col md={12}>
                                <p>{t('errors.noNavigation')}</p>
                            </Col>
                        )}
                        {filteredData.map(item => (
                            isItemVisible(item.key) && (
                                <Col xs={12} sm={6} md={4} lg={3} key={item.id}>
                                    <Card
                                        id={item.id}
                                        className={`h-100 landing-page-card ${item.key === 'isVisibleEstox' && !estoxLinkFetched ? 'disabled' : ''}`}
                                        onClick={() => handleCardClick(item)}
                                        style={item.key === 'isVisibleEstox' && !estoxLinkFetched ? { opacity: 0.5, pointerEvents: 'none' } : {}}
                                    >
                                        <Card.Title
                                            className={
                                                item.isITAABlock ? 'Landing-Page-Card-Title-Admin' :
                                                    'Landing-Page-Card-Title'
                                            }
                                        >
                                            <p>{item.title}</p>
                                        </Card.Title>
                                        <Card.Body>
                                            <Card.Text>{item.subtitle}</Card.Text>
                                            <div className="d-grid gap-2 mt-auto align-self-end card-button">
                                                {(item.faqLink && item.key !== 'isVisibleEloket') && (
                                                    <Button
                                                        className={
                                                            item.isITAABlock ? 'btn-home-Admin' : 'btn btn-home'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            window.open(item.faqLink, '_blank');
                                                        }}
                                                        disabled={(item.key === 'isVisibleEloket')}
                                                    >
                                                        FAQ
                                                    </Button>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        ))}
                    </Row>

                    {showAddModal && (
                        <GeneralAssembly
                            show={showAddModal}
                            handleClose={() => setShowAddModal(false)}
                        />
                    )}

                    {showMandateModal && (
                        <Mandate show={showMandateModal} userDetail={userDetail} handleClose={() => setShowMandateModal(false)} />
                    )}

                    {showEstoxModal && (
                        <Estox show={showEstoxModal} userDetail={userDetail} handleClose={() => setShowEstoxModal(false)} />
                    )}

                    <div>
                        <LandingPageModal
                            showModal={showNaturalPersonConfirmationModal}
                            handleClose={() => setShowNaturalPersonConfirmationModal(false)}
                            validationDataToModal={naturalPersonInformationValidationDataState}
                            naturalPersonId={userDetail.id}
                            canIgnore={naturalPersonInformationValidationDataState.isFirstValidation || naturalPersonInformationValidationDataState.validateNationalNumber}
                        />
                    </div>

                    <div className='link-group-Landing-page'>
                        <a className='link-tag' target='_blank' href={t('sidebar.urls.home')}>ITAA Website</a>
                        <a className='link-tag' target='_blank' href={t('sidebar.urls.privacy')}> | Privacy</a>
                        <a className='link-tag' target='_blank' href={t('sidebar.urls.cookies')}> | Cookies</a>
                    </div>
                </>
            )}
        </div>
    )
}

export default LandingPage;